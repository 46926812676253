import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import BgObject1 from '@/assets/img/bg_object_pledge_01.png';
import BgObject2 from '@/assets/img/bg_object_pledge_02.png';
import BgObject3 from '@/assets/img/bg_object_pledge_03.png';
import IconBenefitArrow from '@/assets/img/ico_pledge_effect_arrow@2x.png';
import IconCategoryOff1 from '@/assets/img/icon_pledge_category_01_off@2x.png';
import IconCategoryOn1 from '@/assets/img/icon_pledge_category_01_on@2x.png';
import IconCategoryOff2 from '@/assets/img/icon_pledge_category_02_off@2x.png';
import IconCategoryOn2 from '@/assets/img/icon_pledge_category_02_on@2x.png';
import IconCategoryOff3 from '@/assets/img/icon_pledge_category_03_off@2x.png';
import IconCategoryOn3 from '@/assets/img/icon_pledge_category_03_on@2x.png';
import IconCategoryOff4 from '@/assets/img/icon_pledge_category_04_off@2x.png';
import IconCategoryOn4 from '@/assets/img/icon_pledge_category_04_on@2x.png';
import IconCategoryOff5 from '@/assets/img/icon_pledge_category_05_off@2x.png';
import IconCategoryOn5 from '@/assets/img/icon_pledge_category_05_on@2x.png';
import IconCategoryOff6 from '@/assets/img/icon_pledge_category_06_off@2x.png';
import IconCategoryOn6 from '@/assets/img/icon_pledge_category_06_on@2x.png';
import IconCategoryOff7 from '@/assets/img/icon_pledge_category_07_off@2x.png';
import IconCategoryOn7 from '@/assets/img/icon_pledge_category_07_on@2x.png';
import ImgPledge1 from '@/assets/img/img_pledge_01@2x.png';
import ImgPledge2 from '@/assets/img/img_pledge_02@2x.png';
import ImgPledge3 from '@/assets/img/img_pledge_03@2x.png';
import ImgBenefit from '@/assets/img/img_pledge_benefit.png';
import ImgChildren1 from '@/assets/img/img_pledge_children_01.png';
import ImgChildren1M from '@/assets/img/img_pledge_children_01M@2x.png';
import ImgChildren2 from '@/assets/img/img_pledge_children_02.png';
import ImgChildren2M from '@/assets/img/img_pledge_children_02M@2x.png';
import ImgChildren3 from '@/assets/img/img_pledge_children_03.png';
import ImgChildren3M from '@/assets/img/img_pledge_children_03M@2x.png';
import ImgBenefit1 from '@/assets/img/img_pledge_effect_01@2x.png';
import ImgBenefit2 from '@/assets/img/img_pledge_effect_02@2x.png';
import ImgBenefit3 from '@/assets/img/img_pledge_effect_03@2x.png';
import ImgGraph1 from '@/assets/img/Img_pledge_graph_01@2x.png';
import ImgGraph2 from '@/assets/img/Img_pledge_graph_02@2x.png';
import ImgGraph3 from '@/assets/img/Img_pledge_graph_03@2x.png';
import ImgInterview1 from '@/assets/img/img_pledge_interview_01.png';
import ImgInterview2 from '@/assets/img/img_pledge_interview_02.png';
import ImgInterview3 from '@/assets/img/img_pledge_interview_03.png';
import ImgInterview4 from '@/assets/img/img_pledge_interview_04.png';
import ImgInterview5 from '@/assets/img/img_pledge_interview_05.png';
import ImgInterview6 from '@/assets/img/img_pledge_interview_06.png';
import ImgInterview7 from '@/assets/img/img_pledge_interview_07.png';
import AccordionComponent, { AccordionItem } from '@/components/Accordion';
import AnchorText from '@/components/AnchorText';
import Button from '@/components/Button';
import { SwiperContainer } from '@/components/Card/RoundThumbnailCard';
import Container from '@/components/Container';
import Image from '@/components/Image';
import PageComponent from '@/components/PageComponent';
import PledgeTabPanel, {
  CategoryData,
  ContentsData,
  GiftData,
  PriceData,
} from '@/components/PledgeTab';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import { MoreViewLink, Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { SelectItemDefaultData } from '@/helpers/GtmHelper';
import {
  SUPPORT_CATEGORY_REGULAR_WORLD,
  SUPPORT_CATEGORY_REGULAR_AIDS,
  SUPPORT_CATEGORY_REGULAR_REFUGEE,
  SUPPORT_TERM_REGULAR,
} from '@/helpers/SupportHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { navigate, PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import SwiperCore, { A11y, EffectFade, Navigation, Scrollbar } from 'swiper';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { GtmButtonDonate } from '@/components/Button.gtm';

SwiperCore.use([Navigation, Scrollbar, A11y, EffectFade]);

const Title = styled(Tit)`
  margin-bottom: 96px;
  ${breakpoint(`mobile`)} {
    margin-bottom: 72px;
  }
`;
const TitleContainer = styled.div`
  position: relative;
`;

const OneOffList = styled.ul`
  margin-left: -16px;
  margin-right: -16px;
  display: flex;
  align-items: stretch;

  & > li {
    width: 33.33%;
    padding: 0 16px;
  }

  ${breakpoint(`mobile`)} {
    flex-wrap: wrap;
    & > li {
      width: 100%;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const OneOffItem = styled.div`
  border: 1px solid #e5e6e8;
  background: #fff;
  padding: 24px 24px 128px;
  position: relative;
  width: 100%;
  height: 100%;

  ${breakpoint(`mobile`)} {
    padding: 16px;
    padding-bottom: 24px;
  }
`;
const OneOffThumb = styled.div`
  img {
    max-width: 100%;
  }
  ${breakpoint(`mobile`)} {
    img {
      width: 100%;
    }
  }
`;
const OneOffFlex = styled.div`
  ${breakpoint(`mobile`)} {
    width: 100%;
    height: auto;
    display: block;
  }
`;
const OneOffText = styled.div`
  width: 100%;
  letter-spacing: -0.8px;
  margin-top: 18px;
  p {
    margin-top: 16px;
  }
  ${breakpoint(1280)} {
    p {
      min-height: 72px;
    }
  }
  ${breakpoint(`mobile`)} {
    padding: 16px 0 0 0;
    margin-top: 0;
    p {
      min-height: auto;
    }
  }
`;
const ButtonContainer = styled.div`
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 32px;

  ${breakpoint(`mobile`)} {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    width: 100%;
    margin-top: 32px;
  }
`;
const BenefitImageObject = styled.div`
  position: relative;
  width: 100%;
  padding-top: 125%;
  height: 0;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 125%;
    background: #e2f0f6;
    position: absolute;
    left: -64px;
    top: 74px;
    transform: rotate(-3deg);
    z-index: -1;
  }

  .img-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;
const BenefitList = styled.ul`
  width: 480px;
  ${breakpoint(1024)} {
    width: 46%;
  }
  ${breakpoint(`mobile`)} {
    width: 100%;
    margin-top: 120px;
  }
`;
const BenefitItem = styled.li`
  background: #f8f9fa;
  border-radius: 40px;
  font-weight: bold;
  padding: 12px;
  text-align: center;
  ${AnchorText} {
    text-decoration: none;
    vertical-align: baseline;
    color: #1cabe2;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const PlegdeBenefit = styled.div`
  display: flex;
  justify-content: space-between;

  .col-img {
    width: 448px;
  }

  ${BenefitItem} + ${BenefitItem} {
    margin-top: 20px;
  }

  ${breakpoint(900)} {
    margin-left: -16px;
    margin-right: -16px;

    .col-img,
    ${BenefitList} {
      width: 50%;
      padding: 0 16px;
    }
  }

  ${breakpoint(`mobile`)} {
    flex-wrap: wrap;
    .col-img,
    ${BenefitList} {
      width: 100%;
    }
  }
`;
const ObjectSection = styled(Section)`
  background: #374ea2;
  padding-bottom: 80px !important;
  .swiper-scrollbar {
    position: absolute;
    height: 4px;
    width: 100%;
    height: 4px;
    bottom: 0;
    &::before {
      content: '';
      background: #e2e3e3;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      opacity: 0.4;
    }
    .swiper-scrollbar-drag {
      height: 4px;
      background: #1cabe2;
    }
  }
`;

const BenefitDetail = styled.div`
  ${SwiperContainer} {
    margin-left: -16px;
    margin-right: -16px;
    width: auto;
    padding-bottom: 40px;
  }

  .swiper-scrollbar {
    left: 16px;
    right: 16px;
    width: auto;
  }
  .swiper-slide {
    width: 384px;
    padding: 0 16px;
    margin: 0 !important;

    .card {
      width: 100%;
      position: relative;
      padding-bottom: 302px;

      &::after {
        content: '';
        width: 56px;
        height: 56px;
        background: url(${IconBenefitArrow}) no-repeat center center;
        background-size: 100%;
        position: absolute;
        left: 50%;
        bottom: 86px;
        transform: translate(-50%, 0);
      }
    }
  }
`;
const BenefitImage = styled.div`
  img {
    max-width: 100%;
  }
`;
const BenefitText = styled.div`
  overflow: hidden;
  float: right;
  border-radius: 0 0 40px 0;
  width: 284px;
  min-height: 158px;
  padding: 24px;
  margin: -55px 0 0 0;
  line-height: 1.68em;
  background: #fff;
  position: relative;
  z-index: 1;

  ${breakpoint(`mobile`)} {
    width: 80%;
  }
`;
const BenefitGraph = styled.div`
  width: 272px;
  margin: 0 auto;
  img {
    max-width: 100%;
  }
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 302px;
    height: 315px;
    position: absolute;
    left: -40px;
    top: -67px;
    z-index: -1;
  }
  &.object-01 {
    &::before {
      background: url(${BgObject1}) no-repeat center center;
      background-size: 100%;
    }
  }
  &.object-02 {
    &::before {
      background: url(${BgObject2}) no-repeat center center;
      background-size: 100%;
    }
  }
  &.object-03 {
    &::before {
      background: url(${BgObject3}) no-repeat center center;
      background-size: 100%;
    }
  }
`;
const GraphText = styled.div`
  margin-top: 32px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  letter-spacing: -1.2px;
  font-weight: bold;
  span {
    font-size: 32px;
    color: #ffdc0e;
    letter-spacing: -1.6px;
    display: block;
  }
  ${breakpoint(`mobile`)} {
    font-size: 16px;
    span {
      font-size: 20px;
    }
  }
`;

const SectionSlide = styled(Section)``;
const ChildrenSwiper = styled(SwiperBasic)`
  padding: 0;
  position: relative;

  ${SwiperContainer} {
    padding-bottom: 0;
  }
  .swiper-slide {
    padding-bottom: 214px;
    opacity: 0 !important;

    &.swiper-slide-active {
      opacity: 1 !important;
    }
  }

  ${Container} {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    text-align: right;
  }
  .swiper-button-prev,
  .swiper-button-next {
    z-index: 2;
    bottom: 48px;
    right: 50%;
  }

  .swiper-button-prev {
    margin-right: -430px;
  }
  .swiper-button-next {
    margin-right: -510px;
  }

  ${breakpoint(1160)} {
    .swiper-slide {
      padding-bottom: 18.45vw;
    }

    .swiper-button-prev {
      margin: 0;
      right: 148px;
    }
    .swiper-button-next {
      margin: 0;
      right: 68px;
    }
  }
  ${breakpoint(`tablet`)} {
    .swiper-button-prev,
    .swiper-button-next {
      display: block;
      bottom: 24px;
    }
    .swiper-button-prev {
      right: 124px;
    }
    .swiper-button-next {
      right: 44px;
    }
  }

  ${breakpoint(`mobile`)} {
    .swiper-slide {
      padding-bottom: 133px;
    }
  }
`;

const ChildrenSwiperText = styled.div`
  background: #f8f9fa;
  padding: 72px 96px;
  padding-bottom: 118px;
  width: 100%;
  max-width: 640px;
  display: inline-block;
  font-weight: bold;
  text-align: left;

  ${breakpoint(1160)} {
    padding: 6.21vw 8.28vw;
    padding-bottom: 10.17vw;
  }

  ${breakpoint(`mobile`)} {
    padding: 40px;
    padding-bottom: 57px;
  }
`;
const Percent = styled.strong`
  display: block;
  font-size: 48px;
  color: #1cabe2;
  margin-top: 24px;
  ${breakpoint(1024)} {
    font-size: 36px;
  }
  ${breakpoint(`mobile`)} {
    font-size: 26px;
  }
`;
const NavigationButton = styled.div`
  padding: 24px 48px 24px 24px;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  ${breakpoint(`mobile`)} {
    font-size: 16px;
    padding: 20px 40px 20px 0;
  }
`;
const AccordionBody = styled.div`
  padding: 24px;
  font-size: 16px;
  color: #2d2926;
  ${breakpoint(`mobile`)} {
    font-size: 14px;
    padding: 16px 0;
  }
  // blut css 보정
  ul,
  ol,
  li {
    list-style: inherit;
    list-style-position: inside;
    list-style-type: inherit;
  }
  ol {
    list-style-type: demical;
  }
  ul {
    list-style-type: disc;
  }
  a {
    color: #1cabe2;
  }
`;

const prices: PriceData[] = [
  {
    id: 1,
    priceCode: `30,000원`,
    contentKey: 1,
    priceValue: `30000`,
  },
  {
    id: 2,
    priceCode: `50,000원`,
    contentKey: 2,
    priceValue: `50000`,
  },
  {
    id: 3,
    priceCode: `100,000원`,
    contentKey: 3,
    priceValue: `100000`,
  },
];
const categories: CategoryData[] = [
  {
    id: 1,
    categoryName: `교육`,
    categoryIcon: `${IconCategoryOff1}`,
    categoryIconActive: `${IconCategoryOn1}`,
  },
  {
    id: 2,
    categoryName: `영양`,
    categoryIcon: `${IconCategoryOff2}`,
    categoryIconActive: `${IconCategoryOn2}`,
  },
  {
    id: 3,
    categoryName: `예방접종`,
    categoryIcon: `${IconCategoryOff3}`,
    categoryIconActive: `${IconCategoryOn3}`,
  },
  {
    id: 4,
    categoryName: `말라리아`,
    categoryIcon: `${IconCategoryOff4}`,
    categoryIconActive: `${IconCategoryOn4}`,
  },
  {
    id: 5,
    categoryName: `건강`,
    categoryIcon: `${IconCategoryOff5}`,
    categoryIconActive: `${IconCategoryOn5}`,
  },
  {
    id: 6,
    categoryName: `깨끗한 식수`,
    categoryIcon: `${IconCategoryOff6}`,
    categoryIconActive: `${IconCategoryOn6}`,
  },
  {
    id: 7,
    categoryName: `이동수단과 보호시설`,
    categoryIcon: `${IconCategoryOff7}`,
    categoryIconActive: `${IconCategoryOn7}`,
  },
];
const contents: ContentsData[] = [
  {
    id: 1,
    title: `"유니세프의 지원으로 생활고로 그만두었던 학교도 다시 다닐 수 있게 되었어요."`,
    name: `– 아리나 (13세, 카자흐스탄 수혜 어린이)`,
    imgPath: `${ImgInterview1}`,
  },
  {
    id: 2,
    title: `"일어설 힘도 없었는데 유니세프의 지원 덕분에 빠르게 건강을 회복하고 있어요."`,
    name: `– 11개월 된 젠티를 둔 마가렛 (남수단)`,
    imgPath: `${ImgInterview2}`,
  },
  {
    id: 3,
    title: `"유니세프가 백신을 공급하며 지역사회와 협력하고 신뢰할 수 있도록 노력하기 때문에, 언젠가는 이 곳에서 소아마비 예방접종 캠페인을 하지 않는 날이 오리라 믿고 있어요."`,
    name: `– 수반 (파키스탄 예방접종 담당)`,
    imgPath: `${ImgInterview3}`,
  },
  {
    id: 4,
    title: `"열병과 오염된 물, 모기는 사라지지 않고 계속 반복될 거예요. 유니세프에서 지원해주신 모기장 덕분에, 모기로 매개되는 질병들로부터 보호받을 수 있게 되었어요."`,
    name: `– 소니아 (앙골라)`,
    imgPath: `${ImgInterview4}`,
  },
  {
    id: 5,
    title: `"유니세프를 통해 숙련된 의료진을 만나 신생아 건강 관리법을 배우며 좋은 부모가 되기 위해 노력할 수 있게 되었어요."`,
    name: `– 하자라 (31세, 나이지리아)`,
    imgPath: `${ImgInterview5}`,
  },
  {
    id: 6,
    title: `"깨끗한 물과 비누, 알려주신 손 씻기 방법 덕분에 가족들을 질병으로부터 지키는 슈퍼 영웅이 된 기분이었어요."`,
    name: `– 아마니 (요르단 수혜 어린이)`,
    imgPath: `${ImgInterview6}`,
  },
  {
    id: 7,
    title: `"유니세프가 지원하는 보호 센터에서 심리사회 상담을 받으며 친구들과 함께 배우고 어울려 놀며 지내요. 이제는 건강한 미래를 위한 꿈을 꿀 수 있다고 믿어요."`,
    name: `– 필립 (15세, 콩고 수혜 어린이)`,
    imgPath: `${ImgInterview7}`,
  },
];
const gifts: GiftData[] = [
  {
    id: 0,
    name: `교육`,
    status: `30,000원`,
    giftText1: `매월 공책 60권, 연필 600자루 전달`,
    giftText2: `어린이들이 공부할 수 있는 시설 지원`,
  },
  {
    id: 1,
    name: `교육`,
    status: `50,000원`,
    giftText1: `매월 공책 100권, 연필 1,000자루 전달`,
    giftText2: `신생아와 영유아의 면역력을 높일 수 있는 보건사업 지원`,
  },
  {
    id: 2,
    name: `교육`,
    status: `100,000원`,
    giftText1: `매월 공책 200권, 연필 2,000자루 전달`,
    giftText2: `신생아와 영유아의 면역력을 높일 수 있는 보건사업 지원`,
  },
  {
    id: 3,
    name: `영양`,
    status: `30,000원`,
    giftText1: `매년 영양실조에 걸린 어린이 40명 더 생존`,
    giftText2: `영양실조 예방 활동 지원`,
  },
  {
    id: 4,
    name: `영양`,
    status: `50,000원`,
    giftText1: `매년 영양실조에 걸린 어린이 67명 더 생존`,
    giftText2: `영양실조 예방 활동 지원`,
  },
  {
    id: 5,
    name: `영양`,
    status: `100,000원`,
    giftText1: `매년 영양실조에 걸린 어린이 133명 더 생존`,
    giftText2: `영양실조 예방 활동 지원`,
  },
  {
    id: 6,
    name: `예방접종`,
    status: `30,000원`,
    giftText1: `소아마비에 걸리지 않도록 매월 40명의 어린이에게 예방접종 지원`,
  },
  {
    id: 7,
    name: `예방접종`,
    status: `50,000원`,
    giftText1: `소아마비에 걸리지 않도록 매월 67명의 어린이에게 예방접종 지원`,
  },
  {
    id: 8,
    name: `예방접종`,
    status: `100,000원`,
    giftText1: `소아마비에 걸리지 않도록 매월 133명의 어린이에게 예방접종 지원`,
  },
  {
    id: 9,
    name: `말라리아`,
    status: `30,000원`,
    giftText1: `매년 살충 처리된 모기장 100개 전달`,
    giftText2: `매년 말라리아 치료제 400정 전달`,
  },
  {
    id: 10,
    name: `말라리아`,
    status: `50,000원`,
    giftText1: `매년 살충 처리된 모기장 167개 전달`,
    giftText2: `매년 말라리아 치료제 667정 전달`,
  },
  {
    id: 11,
    name: `말라리아`,
    status: `100,000원`,
    giftText1: `매년 살충 처리된 모기장 333개 전달`,
    giftText2: `매년 말라리아 치료제 1,333정 전달`,
  },
  {
    id: 12,
    name: `건강`,
    status: `30,000원`,
    giftText1: `매월 어린이 200명에게 구강수분보충염 제공`,
    giftText2: `신생아와 영유아의 면역력을 높일 수 있는 보건사업 지원`,
  },
  {
    id: 13,
    name: `건강`,
    status: `50,000원`,
    giftText1: `매월 어린이 333명에게 구강수분보충염 제공`,
    giftText2: `어린이들이 공부할 수 있는 시설 지원`,
  },
  {
    id: 14,
    name: `건강`,
    status: `100,000원`,
    giftText1: `매월 어린이 667명에게 구강수분보충염 제공`,
    giftText2: `신생아와 영유아의 면역력을 높일 수 있는 보건사업 지원`,
  },
  {
    id: 15,
    name: `깨끗한 식수`,
    status: `30,000원`,
    giftText1: `매월 27,500L의 깨끗한 물 제공`,
    giftText2: `수질 관리 지원, 위생교육, 펌프 보급`,
  },
  {
    id: 16,
    name: `깨끗한 식수`,
    status: `50,000원`,
    giftText1: `매월 45,833L의 깨끗한 물 제공`,
    giftText2: `수질 관리 지원, 위생교육, 펌프 보급`,
  },
  {
    id: 17,
    name: `깨끗한 식수`,
    status: `100,000원`,
    giftText1: `매월 91,667L의 깨끗한 물 제공`,
    giftText2: `수질 관리 지원, 위생교육, 펌프 보급`,
  },
  {
    id: 18,
    name: `이동수단과 보호시설`,
    status: `30,000원`,
    giftText1: `원치 않는 결혼을 하는 여자 어린이, 일하느라 학교에 가지 못 하는 어린이, 강제로 전쟁터에 보내지는 소년병 어린이 등 어린이의 
    권리 보호`,
  },
  {
    id: 19,
    name: `이동수단과 보호시설`,
    status: `50,000원`,
    giftText1: `원치 않는 결혼을 하는 여자 어린이, 일하느라 학교에 가지 못 하는 어린이, 강제로 전쟁터에 보내지는 소년병 어린이 등 어린이의 
    권리 보호`,
  },
  {
    id: 20,
    name: `이동수단과 보호시설`,
    status: `100,000원`,
    giftText1: `원치 않는 결혼을 하는 여자 어린이, 일하느라 학교에 가지 못 하는 어린이, 강제로 전쟁터에 보내지는 소년병 어린이 등 어린이의 
    권리 보호`,
  },
];
export interface FaqData {
  id: string | number;
  title?: string;
  contents?: string;
}
const PledgeIndex: React.FC<PageProps> = ({ location }) => {
  const [faqLists, setFaqLists] = useState<FaqData[]>([]);

  const loadFaqLists = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['260'],
        optData2: '101',
        pagePerCount: 5,
        statusCode: '1',
      });
      const pledgeFaq = data as any;
      setFaqLists(
        pledgeFaq.map((faq: BoardVo) => ({
          id: faq.boardIndexNumber,
          title: faq.subject,
          contents: faq.contents,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadFaqLists();
  }, [loadFaqLists]);

  const getSupportPageUrl = (supportCategory: string) => {
    return `/donate/?supportTerm=FM01&supportCategory=${supportCategory}`;
  };

  // 후원하기 버튼 클릭시 gtm 처리
  const defaultSelectItem = {
    campaignName: 'none',
    campaignPagenum: '',
    promotionName: 'none',
    promotionId: '',
    itemListName: '',
    itemBreadscrumb: '',
    itemBrand: '',
  };

  return (
    <LayoutWithTitle
      location={location}
      title="정기후원"
      description="for every child, monthly giving"
    >
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <Tit size="s1">
              <PageComponent id="title1">매달 어린이를 위한 약속</PageComponent>
            </Tit>

            <div
              className="head-dec"
              css={`
                margin-top: 32px;
                line-height: 1.7;
                ${breakpoint(640)} {
                  margin-top: 24px;
                }
              `}
            >
              <PageComponent id="content1">
                매달 일정 금액을 꾸준히 후원해 주시면
                <br />
                어린이에게 더 큰 도움을 줄 수 있습니다.
                <br />
                정기후원은 안정적인 후원금 확보로 어린이의 행복한 삶을
                <br />
                위한 장기적인 지원을 가능하게 해 줍니다.
              </PageComponent>
            </div>
          </SectionHeader>

          <OneOffList>
            <li>
              <OneOffItem>
                <OneOffThumb>
                  <img src={ImgPledge1} alt="" />
                </OneOffThumb>
                <OneOffFlex>
                  <OneOffText>
                    <Tit size="s4" color="sky">
                      세계 어린이 돕기
                    </Tit>
                    <p>
                      전 세계 도움이 필요한 어린이들이 건강하게 자라고 안전하게
                      살 수 있도록 지원합니다.
                    </p>
                  </OneOffText>
                  <ButtonContainer>
                    <GtmButtonDonate
                      type="button"
                      text="후원하기"
                      color="yellow"
                      full
                      goto={getSupportPageUrl(SUPPORT_CATEGORY_REGULAR_WORLD)}
                      gtmInfo={{
                        ...SelectItemDefaultData,
                        itemId: SUPPORT_CATEGORY_REGULAR_WORLD,
                        itemName: '세계 어린이 돕기',
                        itemCategory: SUPPORT_TERM_REGULAR,
                      }}
                      onClickPreEvent={() => {
                        // TODO onClick before call gtm
                        // alert('click');
                      }}
                    />
                  </ButtonContainer>
                </OneOffFlex>
              </OneOffItem>
            </li>
            <li>
              <OneOffItem>
                <OneOffThumb>
                  <img src={ImgPledge2} alt="" />
                </OneOffThumb>
                <OneOffFlex>
                  <OneOffText>
                    <Tit size="s4" color="sky">
                      난민 어린이 돕기
                    </Tit>
                    <p>
                      자연재해, 전쟁으로 집을 잃고 고생하는 난민 어린이를
                      지원합니다.
                    </p>
                  </OneOffText>
                  <ButtonContainer>
                    <GtmButtonDonate
                      type="button"
                      text="후원하기"
                      color="yellow"
                      full
                      goto={getSupportPageUrl(SUPPORT_CATEGORY_REGULAR_REFUGEE)}
                      gtmInfo={{
                        ...SelectItemDefaultData,
                        itemId: SUPPORT_CATEGORY_REGULAR_REFUGEE,
                        itemName: '난민 어린이 돕기',
                        itemCategory: SUPPORT_TERM_REGULAR,
                      }}
                      onClickPreEvent={() => {
                        // TODO onClick before call gtm
                        // alert('click');
                      }}
                    />
                  </ButtonContainer>
                </OneOffFlex>
              </OneOffItem>
            </li>
            <li>
              <OneOffItem>
                <OneOffThumb>
                  <img src={ImgPledge3} alt="" />
                </OneOffThumb>
                <OneOffFlex>
                  <OneOffText>
                    <Tit size="s4" color="sky">
                      에이즈 감염 어린이 돕기
                    </Tit>
                    <p>
                      에이즈로 부모를 잃은 어린이를 보호하고 모자감염 예방과
                      치료를 지원합니다.
                    </p>
                  </OneOffText>
                  <ButtonContainer>
                    <GtmButtonDonate
                      type="button"
                      text="후원하기"
                      color="yellow"
                      full
                      goto={getSupportPageUrl(SUPPORT_CATEGORY_REGULAR_AIDS)}
                      gtmInfo={{
                        ...SelectItemDefaultData,
                        itemId: SUPPORT_CATEGORY_REGULAR_AIDS,
                        itemName: '에이즈 감염 어린이 돕기',
                        itemCategory: SUPPORT_TERM_REGULAR,
                      }}
                      onClickPreEvent={() => {
                        // TODO onClick before call gtm
                        // alert('click');
                      }}
                    />
                  </ButtonContainer>
                </OneOffFlex>
              </OneOffItem>
            </li>
          </OneOffList>
        </Container>
      </Section>
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <Tit size="s1">유니세프 정기후원자가 되면</Tit>
          </SectionHeader>
          <PlegdeBenefit>
            <div className="col-img">
              <BenefitImageObject>
                <div
                  className="img-box"
                  css={`
                    background-image: url(${ImgBenefit});
                  `}
                />
              </BenefitImageObject>
            </div>
            <BenefitList>
              <BenefitItem>첫 정기후원 감사 및 안내</BenefitItem>
              <BenefitItem>
                후원자 소식지 제공 (연 4회){` `}
                <AnchorText
                  onClick={() => {
                    navigate(`/what-we-do/database?category=KW02#tagBox`);
                  }}
                >
                  [더 보기]
                </AnchorText>
              </BenefitItem>
              <BenefitItem>
                연차보고서 제공 (연 1회){` `}
                <AnchorText
                  onClick={() => {
                    navigate(`/what-we-do/database?category=KW03#tagBox`);
                  }}
                >
                  [더 보기]
                </AnchorText>
              </BenefitItem>
              <BenefitItem>어린이 정기후원 감사인사</BenefitItem>
              <BenefitItem>1주년, 5주년, 10주년 등 감사카드/감사패</BenefitItem>
              <BenefitItem>기부금 영수증</BenefitItem>
              <BenefitItem>
                후원자 해외 현장 방문 (연 1회){` `}
                <AnchorText
                  onClick={() => {
                    navigate(`/involve/donors/field-trip`);
                  }}
                >
                  [더 보기]
                </AnchorText>
              </BenefitItem>
              <BenefitItem>
                정기후원자 초청행사 (수시){` `}
                <AnchorText
                  onClick={() => {
                    navigate(`/involve/donors/event`);
                  }}
                >
                  [더 보기]
                </AnchorText>
              </BenefitItem>
            </BenefitList>
          </PlegdeBenefit>
        </Container>
      </Section>
      <ObjectSection className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <Tit size="s1" color="white">
              전 세계 어린이들의 변화, <br />
              후원자님이 있어 가능합니다
            </Tit>
          </SectionHeader>
          <BenefitDetail>
            <SwiperContainer
              slidesPerView="auto"
              navigation={false}
              allowTouchMove={false}
              scrollbar={{ draggable: false }}
              breakpoints={{
                1920: {
                  slidesPerView: `auto`,
                  allowTouchMove: false,
                },
                1024: {
                  slidesPerView: 3,
                  allowTouchMove: false,
                },
                640: {
                  slidesPerView: 2,
                  allowTouchMove: true,
                },
                320: {
                  slidesPerView: 1,
                  allowTouchMove: true,
                },
              }}
            >
              <SwiperSlide>
                <div className="card">
                  <BenefitImage>
                    <img src={ImgBenefit1} alt="" />
                  </BenefitImage>
                  <BenefitText>
                    매년 어린이 약 <strong>500만 명</strong>이 다섯 번째 생일을
                    맞기 전 목숨을 잃습니다.
                  </BenefitText>
                </div>

                <BenefitGraph className="object-01">
                  <img src={ImgGraph1} alt="" />
                  <GraphText>
                    5세 미만 어린이 사망률
                    <span>50% 감소</span>
                  </GraphText>
                </BenefitGraph>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card">
                  <BenefitImage>
                    <img src={ImgBenefit2} alt="" />
                  </BenefitImage>
                  <BenefitText>
                    현재 전 세계 어린이 <strong>10명 중 1명</strong>은 초등
                    교육을 받지 못하고, <strong>3명 중 1명</strong>은 10세가 될
                    때까지 글을 읽거나 이해하지 못하는 어린이는 전 세계 어린이의{' '}
                    <strong>64.3%</strong>로 추정됩니다.
                  </BenefitText>
                </div>

                <BenefitGraph className="object-02">
                  <img src={ImgGraph2} alt="" />
                  <GraphText>
                    초등교육을 받지 못한 어린이
                    <span>35% 감소</span>
                  </GraphText>
                </BenefitGraph>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card">
                  <BenefitImage>
                    <img src={ImgBenefit3} alt="" />
                  </BenefitImage>
                  <BenefitText>
                    5세 미만 어린이 <strong>3명 중 1명</strong>은 영양실조이고,
                    어린이와 청소년 <strong>약 260만 명</strong>이 HIV에
                    감염되어 살아가며, 2022년 어린이 <strong>10만 명</strong>이
                    에이즈 관련 질병으로 사망했습니다.
                  </BenefitText>
                </div>

                <BenefitGraph className="object-03">
                  <img src={ImgGraph3} alt="" />
                  <GraphText>
                    HIV 신규 감염 어린이(0-9세)
                    <span>75% 감소</span>
                  </GraphText>
                </BenefitGraph>
              </SwiperSlide>
            </SwiperContainer>
          </BenefitDetail>
        </Container>
      </ObjectSection>
      <SectionSlide className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <Tit size="s1">
              하지만 여전히 도움이 필요한 <br />
              어린이가 많습니다
            </Tit>
          </SectionHeader>
        </Container>
        <ChildrenSwiper>
          <SwiperContainer navigation scrollbar={false} effect="fade">
            <SwiperSlide>
              <Image pcSrc={ImgChildren1} mobileSrc={ImgChildren1M} />
              <Container>
                <ChildrenSwiperText>
                  심각한 빈곤을 겪는 전 세계 어린이
                  <Percent>3명 중 1명</Percent>
                </ChildrenSwiperText>
              </Container>
            </SwiperSlide>
            <SwiperSlide>
              <Image pcSrc={ImgChildren2} mobileSrc={ImgChildren2M} />
              <Container>
                <ChildrenSwiperText>
                  필수 예방접종을 받지 못하는 어린이
                  <Percent>2,050만 명</Percent>
                </ChildrenSwiperText>
              </Container>
            </SwiperSlide>
            <SwiperSlide>
              <Image pcSrc={ImgChildren3} mobileSrc={ImgChildren3M} />
              <Container>
                <ChildrenSwiperText>
                  깨끗하고 안전한 식수를 사용하지 못하는 어린이와 가족
                  <Percent>5명 중 1명</Percent>
                </ChildrenSwiperText>
              </Container>
            </SwiperSlide>
          </SwiperContainer>
        </ChildrenSwiper>
      </SectionSlide>
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <Tit size="s1">어린이는 정기후원을 통해 어떤 도움을 받나요?</Tit>
          </SectionHeader>
          <PledgeTabPanel
            prices={prices}
            categories={categories}
            contents={contents}
            gifts={gifts}
          />
        </Container>
      </Section>
      <Section
        css={`
          padding: 60px 0 210px 0;
          ${breakpoint(`mobile`)} {
            padding: 60px 0 110px 0;
          }
        `}
      >
        <Container>
          <TitleContainer>
            <Title size="s1">
              정기후원 관련
              <br />
              자주 묻는 질문
            </Title>
            <MoreViewLink to="/faq?category=1&hashTag=101#categorySection">
              <span>더 보기</span>
            </MoreViewLink>
          </TitleContainer>
          {faqLists.length > 0 && (
            <AccordionComponent initialKey={faqLists[0].id}>
              {faqLists.map((faq) => (
                <AccordionItem
                  key={faq.id}
                  toggleKey={faq.id}
                  renderToggle={(onClick) => (
                    <NavigationButton
                      onClick={onClick}
                      className="accordion-opener"
                    >
                      {faq.title}
                    </NavigationButton>
                  )}
                >
                  <AccordionBody
                    dangerouslySetInnerHTML={{ __html: faq.contents }}
                  />
                </AccordionItem>
              ))}
            </AccordionComponent>
          )}
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default PledgeIndex;
